<template>

	<el-dialog title="编辑反馈信息" v-model="reportinfo" width="70%" center append-to-body @close='closeDialog()'>

		<el-form label-width="100px" class="ruleForm">
			<el-form-item label="反馈用户" prop="user_name" >
			  <el-input v-model="user_name" :readonly="true" ></el-input>
			</el-form-item>
			<el-form-item label="所属项目" prop="project" >
			  <el-input v-model="project" :readonly="true"></el-input>
			</el-form-item>
			<el-form-item label="反馈内容" prop="content" >
			  <el-input  type="textarea" v-model="content" :readonly="true"></el-input>
			</el-form-item>
			<el-form-item label="处理内容" prop="memo" >
			  <el-input  type="textarea" v-model="memo" ></el-input>
			</el-form-item>
			<el-form-item label="分类状态" prop="status" >
				<el-radio v-model="status" label="1" border>已处理</el-radio>
				<el-radio v-model="status" label="0" border>未处理</el-radio>
			</el-form-item>
			
		</el-form>
		<div class="ucForm-group-ad">
		  <div class="ucForm-group ucBtn-bg">
		    <button class="ucBtn ucBtn-ok ucBtn-blue" @click="commit()"><i class="iImg"></i>确定</button>
		    <button class="ucBtn ucBtn-refresh ucBtn-gray" @click="closeDialog()"><i
		        class="iImg"></i>返回</button>
		  </div>
		</div>
		<div class="clear"></div>
		
	</el-dialog>

</template>

<script>
	export default {
		created() {
			this.reportinfo = true;
		},

		data() {
			let self = this;
			return {
				reportinfo: false,
				id: this.$parent.id,
				user_name:this.$parent.user_name,
				project:this.$parent.project,
				content:this.$parent.content,
				status:this.$parent.status,
				memo:this.$parent.memo
			
			}
		},

		methods: {
			commit:function(){
				var self = this;
				var req={};
				if(this.$parent.memo==this.memo&&this.$parent.status==this.status){
					self.closeDialog();
					return
				}
				if(this.memo!=""&&this.memo!=null){
					if(this.$parent.status==0){
						req.memo = this.memo;
						req.status = 1;
					}else{
						self.$message("该反馈信息已处理，无法修改！");
						self.closeDialog();
						return
					}
				}else{
					self.$message("请输入处理内容");
					return
				}
				req.id = this.id;
				req.cmd = "updateReport"
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message(res.reason);
					self.closeDialog();
					// self.handleClickQuery()
				}, null, self);
			},
			
			closeDialog: function(){
			  this.reportinfo = false;
			  this.clearinfo();
			  this.$emit('closeReportInfo'); //通知父组件改变。
			},
			clearinfo:function(){
			   this.description = "";
			},

		},


		mounted() {

		},
		components: {

		}
	}
</script>

<style scoped>

</style>
